<template>
	<CRow>
		<CCol sm="4">
			<b-input-group class="mt-3">
				<template #prepend>
					<span class="input-group-text"
						><i class="fas fa-search"></i
					></span>
				</template>
				<b-form-input v-model="searchText"></b-form-input>
			</b-input-group>
		</CCol>
	</CRow>
</template>

<script>
	export default {
		props: {
			textToFilter: {
				type: String,
				default: function() {
					return '';
				},
			},
			cantCharactBegin: {
				type: Number,
				default: function() {
					return 3;
				},
			},
			cantCharactClear: {
				type: Number,
				default: function() {
					return 0;
				},
			},
		},
		data() {
			return {
				searchText: this.textToFilter,
				maxTextLength: this.cantCharactBegin,
				lengthTextToClear: this.cantCharactClear,
			};
		},
		methods: {
			doSearch(value) {
				if (value.length >= this.maxTextLength) {
					this.$emit('search', value);
				} else if (value.length === this.lengthTextToClear) {
					this.$emit('search', null);
				}
			},
		},
		watch: {
			searchText(value) {
				this.doSearch(value);
			},
		},
	};
</script>
