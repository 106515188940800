<template>
	<CRow>
		<CCol sm="12">
			<b-pagination
				v-model="dataPager.currentPage"
				:total-rows="dataPager.totalRecords"
				:per-page="dataPager.recordsPerPage"
				@change="changePage"
				align="right"
				first-number
				last-number
				pills
			>
				<template #prev-text
					><span class="text-primary">Anterior</span></template
				>
				<template #next-text
					><span class="text-success">Siguiente</span></template
				>
				<template #page="{ page, active }">
					<b v-if="active">{{ page }}</b>
					<i v-else>{{ page }}</i>
				</template>
			</b-pagination>
		</CCol>
	</CRow>
</template>

<script>
	export default {
		name: 'pager',
		props: {
			dataPager: {
				type: Object,
				default: function() {
					return {
						currentPage: 1,
						recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
						totalRecords: 0,
					};
				},
			},
		},
		data() {
			return {
				dataForPagination: this.dataPager,
			};
		},
		created() {},
		methods: {
			changePage(page) {
				this.$emit('changePage', page);
			},
		},
	};
</script>

<style></style>
