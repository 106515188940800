<template>
	<div>
		<CCard>
			<CCardHeader>
				<h4 class="center-text">
					Usuarios registrados en el sistema
				</h4>
			</CCardHeader>
			<CCardBody>
				<div v-if="this.showContent === 'information'" id="information">
					<CRow>
						<CCol sm="12">
							<p class="h6 justify-text">
								En este m&oacute;dulo puedes visualizar los
								usuarios registrados actualmente en el sistema.
							</p>
							<p class="h6 justify-text">
								Puedes usar el campo con el icono
								<i class="fas fa-search"></i> para filtrar los
								resultados.
							</p>
							<p class="h6 justify-text">
								Para eliminar el acceso al sistema de un usuario
								haz clic en el &iacute;cono
								<i
									class="fas fa-user-times fa-lg"
									style="color: #E55353;"
								></i>
							</p>
							<p class="h6 justify-text">
								Para editar la inormaci&oacute;n de un usuario
								haz clic en el &iacute;cono
								<i
									class="fas fa-user-edit fa-lg"
									style="color: #39F;"
								></i>
							</p>
							<p class="h6 justify-text">
								<span class="h6 badge badge-danger"
									>Importante:
								</span>
								Recuerda que eliminar el acceso al sistema de un
								usuario es irreversible por lo tanto realiza
								dicho proceso con <b>precauci&oacute;n</b> ya
								que una vez elimines el acceso el usuario
								<b>no podr&aacute; iniciar sesi&oacute;n</b>
								nuevamente en el sistema.
							</p>
						</CCol>
					</CRow>
					<!-- buscador tabla -->
					<search-tables @search="doSearch" />
					<!-- tabla -->
					<CRow>
						<CCol sm="12">
							<p style="margin-bottom: 30px;">
								<span class="float-right"
									>Total de usuarios:
									<CBadge shape="pill" color="info">
										{{
											this.dataForPagination.totalRecords
										}}</CBadge
									>
								</span>
							</p>
							<b-table
								class="text-left"
								stacked="lg"
								striped
								outlined
								hover
								bordered
								:busy.sync="isBusy"
								:items="tableProvider"
								:fields="fieldsTable"
								empty-text="No existen usuarios"
								ref="dataTable"
								show-empty
							>
								<template #empty="scope">
									<p class="h6 justify-text">
										{{ scope.emptyText }}
									</p>
								</template>
								<template #cell(actions)="row" style="">
									<div style="text-align: center;">
										<span
											v-if="
												row.item.idUser !==
													dataUserLoggedIn.idUsers
											"
											@click="
												modalConfirmDelete(row.item)
											"
											style="margin-left:5px; margin-right:5px; cursor: pointer; "
											title="Eliminar"
											v-b-tooltip.hover.v-danger
										>
											<i
												class="fas fa-user-times fa-lg danger-red"
											></i>
										</span>
										<span
											@click="
												goToEditUser(row.item.idUser)
											"
											style="cursor: pointer; margin-left: 10px;"
											title="Editar"
											v-b-tooltip.hover.v-primary
										>
											<i
												class="fas fa-user-edit fa-lg edit-blue"
											></i>
										</span>
									</div>
								</template>
							</b-table>
						</CCol>
					</CRow>
					<!-- paginacion -->
					<pager
						v-if="dataForPagination.totalRecords > 0"
						:data-pager="dataForPagination"
						@changePage="changePage"
					/>
					<!-- modales -->
					<delete-user
						v-if="modalDeleteUser"
						:trigger-modal="true"
						:user-name="userNameClicked"
						@closeModal="modalDeleteUser = false"
						@deleteUser="deleteUser"
					/>
					<success-modal
						v-if="showSuccessModal"
						:trigger-modal="true"
						:title-modal="this.titleSuccessModal"
						:text-modal="this.textSuccessModal"
						:redirect="false"
						@closeModal="showSuccessModal = false"
					/>
				</div>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	import pager from '../../components/general/pager.vue';
	import SearchTables from '../../components/general/searchTables.vue';
	import DeleteUser from '../../components/modals/deleteUser.vue';
	import SuccessModal from '../../components/modals/successModal.vue';
	import adminUsers from '../../functions/functionsAdminUsers';
	export default {
		components: { pager, DeleteUser, SuccessModal, SearchTables },
		name: 'AllUsers',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				showContent: 'information',
				fieldsTable: [
					{
						key: 'idUser',
						label: 'ID',
					},
					{
						key: 'name',
						label: 'Nombre',
					},
					{
						key: 'email',
						label: 'E-Mail',
					},
					{
						key: 'profile',
						label: 'Perfil',
					},
					{
						key: 'actions',
						label: 'Acciones',
					},
				],
				dataTable: [],
				dataForPagination: {
					recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
					currentPage: 1,
					totalRecords: 0,
				},
				page: null,
				isBusy: false,
				modalDeleteUser: false,
				userNameClicked: '',
				idUserClicked: 0,
				showSuccessModal: false,
				titleSuccessModal: '',
				textSuccessModal: '',
				dataUserLoggedIn: {},
				searchText: null,
			};
		},
		beforeCreate: function() {},
		created() {
			this.dataUserLoggedIn = JSON.parse(
				sessionStorage.getItem('userData')
			);
		},
		methods: {
			async tableProvider() {
				let promise = adminUsers.getAllUsers(
					this.dataForPagination.recordsPerPage,
					this.page,
					this.searchText
				);
				try {
					const response = await promise;
					const items = [];
					this.isBusy = false;
					this.dataForPagination = {
						recordsPerPage: response.per_page,
						currentPage: response.current_page,
						totalRecords: response.total,
					};
					for (let index = 0; index < response.data.length; index++) {
						items[index] = {
							idUser: response.data[index].idUsers,
							name: response.data[index].name,
							email: response.data[index].email,
							profile: response.data[index].profile.name,
							idProfile: response.data[index].profile.idProfiles,
						};
					}
					this.dataTable = items;
					return items;
				} catch (error) {
					console.log(error);
					return [];
				}
			},
			changePage(page) {
				this.dataTable = [];
				this.page = page;
				//this.tableProvider();
				this.$refs.dataTable.refresh();
			},
			goToEditUser(idUser) {
				this.$router.push({
					name: 'EditUser',
					params: { userId: idUser },
				});
			},
			modalConfirmDelete(row) {
				this.modalDeleteUser = true;
				this.userNameClicked = row.name;
				this.idUserClicked = row.idUser;
			},
			deleteUser() {
				adminUsers
					.deleteUser(this.idUserClicked)
					.then((response) => {
						this.titleSuccessModal = 'Eliminaci&oacute;n Exitosa';
						this.textSuccessModal =
							'Eliminaste exitosamente el acceso al usuario: <b>' +
							this.userNameClicked +
							'</b>';
						this.modalDeleteUser = false;
						this.showSuccessModal = true;
						//this.tableProvider();
						this.$refs.dataTable.refresh();
					})
					.catch((error) => {
						console.log(error);
					});
			},
			doSearch(value) {
				this.searchText = value;
				this.$refs.dataTable.refresh();
			},
		},
	};
</script>
