<template>
	<b-modal
		v-model="triggerModal"
		centered
		no-close-on-backdrop
		header-bg-variant="success"
		header-text-variant="light"
		style="text-align:justify;"
	>
		<CRow>
			<CCol>
				<p v-html="textModal"></p>
			</CCol>
		</CRow>
		<template v-slot:modal-header>
			<h6 class="modal-title" v-html="titleModal"></h6>
			<CButtonClose @click="closeModal" class="text-white" />
		</template>
		<template v-slot:modal-footer>
			<b-button @click="closeModal" pill variant="success" class="mr-1"
				>Cerrar
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	export default {
		name: 'successModal',
		props: {
			titleModal: {
				type: String,
				default: 'Proceso Exitoso!!!',
			},
			textModal: {
				type: String,
				default: 'Se realizo con &eacute;xito el proceso.',
			},
			triggerModal: {
				type: Boolean,
				default: function() {
					return false;
				},
			},
			redirect: {
				type: Boolean,
				default: function() {
					return true;
				},
			},
			routeToRedirect: {
				type: String,
				default: function() {
					return 'Home';
				},
			},
		},
		data() {
			return {};
		},
		methods: {
			closeModal() {
				if (this.redirect === true) {
					this.$router.push({
						name: this.routeToRedirect,
					});
				} else {
					this.$emit('closeModal');
				}
			},
		},
	};
</script>

<style></style>
