/** funciones para el modulo de administracion de usuarios */
import axiosInfoFeed from '../axiosConfig';
var adminUsers = {
	async getAllProfiles() {
		const response = await axiosInfoFeed.get(
			'api/v1/profiles?sort_by=name'
		);
		this.response = response.data.data;
		return this.response;
	},
	async postNewUser(dataForPost) {
		const response = await axiosInfoFeed.post('api/v1/users', dataForPost);
		this.response = response.data.data;
		return this.response;
	},
	async getSpecificProfile(idProfile) {
		const response = await axiosInfoFeed.post(
			'api/v1/profiles/' + idProfile
		);
		this.response = response.data.data;
		return this.response;
	},
	async getSpecificUser(idUserGet) {
		const response = await axiosInfoFeed.get('api/v1/users/' + idUserGet);
		this.response = response.data.data;
		return this.response;
	},
	async putEditUser(idUsers, dataUserEdit) {
		const response = await axiosInfoFeed.patch(
			'api/v1/users/' + idUsers,
			dataUserEdit
		);
		this.response = response.data.data;
		return this.response;
	},
	async getAllUsers(recordsPerPage, goToPage, filter) {
		let page = goToPage !== null ? '&page=' + goToPage : '';
		let search = filter !== null ? '&like_by=' + encodeURI(filter) : '';
		const response = await axiosInfoFeed.get(
			'api/v1/users?sort_by=name&per_page=' +
				recordsPerPage +
				page +
				search
		);
		this.response = response.data.data;
		return this.response;
	},
	async deleteUser(idUser) {
		const response = await axiosInfoFeed.delete('api/v1/users/' + idUser);
		this.response = response.data.data;
		return this.response;
	},
};

export default adminUsers;
